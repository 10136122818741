@import "src/assets/styles/general/_typography.scss";
@import "src/assets/styles/general/_variables.scss";

.sb-error-page {
  &_wrapper {
    gap: 120px;
    @include media-breakpoint-down(md) {
      gap: 40px;
    }
    img {
      max-width: 100%;
      @include media-breakpoint-down(md) {
        max-width: 165px;
      }
    }
  }
  &_title {
    @include h5;
  }
  &_content {
    @include body-light;
    width: 100%;
    max-width: 526px;

    p {
      margin: 0;
    }
  }
}
