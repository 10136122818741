@mixin jakarta700 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 700;
}
@mixin jakarta600 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 600;
}
@mixin jakarta500 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 500;
}
@mixin jakarta400 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 400;
}
@mixin jakarta300 {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 300;
}
@mixin zodiak700 {
  font-family: "Zodiak700", sans-serif;
}
@mixin zodiak400italic {
  font-family: "Zodiak400italic", sans-serif;
}
@mixin zodiak400 {
  font-family: "Zodiak400", sans-serif;
}
@mixin zodiak300 {
  font-family: "Zodiak300", sans-serif;
  font-weight: 300;
}
@mixin epic {
  @include zodiak400;
  font-size: 96px;
  line-height: 120%;
  @include media-breakpoint-down(lg) {
    font-size: 80px;
  }
  @include media-breakpoint-down(md) {
    font-size: 48px;
  }
}

@mixin epic-dynamic {
  @include zodiak700;
  font-size: 78px;
  line-height: 86px;
  font-style: italic;
  @include media-breakpoint-down(md) {
    font-size: 48px;
    line-height: 56px;
  }
}

@mixin epic-lead {
  @include zodiak300;
  font-size: 24px;
  line-height: 32px;
}

@mixin h1 {
  @include zodiak700;
  font-size: 40px;
  line-height: 120%;
}

@mixin h2 {
  @include zodiak400;
  font-size: 32px;
  line-height: 140%;
}

@mixin h3 {
  @include jakarta400;
  font-size: 28px;
  line-height: 140%;
}

@mixin h4 {
  @include jakarta400;
  font-size: 24px;
  line-height: 140%;
}

@mixin h5 {
  @include jakarta700;
  font-size: 20px;
  line-height: 150%;
}

@mixin body-bold {
  @include jakarta700;
  font-size: 16px;
  line-height: 150%;
}

@mixin body-semibold {
  @include jakarta600;
  font-size: 16px;
  line-height: 150%;
}

@mixin body-light {
  @include jakarta300;
  font-size: 16px;
  line-height: 150%;
}

@mixin body-light-italic {
  @include jakarta300;
  font-size: 16px;
  line-height: 150%;
  font-style: italic;
}

@mixin title {
  @include zodiak700;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 1.28px;
  text-transform: uppercase;
}

@mixin button {
  @include jakarta500;
  font-size: 16px;
  line-height: 32px;
}

@mixin caption {
  @include jakarta400;
  font-size: 14px;
  line-height: 140%;
}

@mixin small {
  @include jakarta400;
  font-size: 12px;
  line-height: 140%;
}
